<template>
	<b-container v-if="$checkPermission('view-company')" fluid id="company">
			
		<cg-loader :display="inProgress"></cg-loader>

		<cg-modal :data="createCompanyFields" @update="createCompany"></cg-modal>

		<cg-localized-alert :alertVariant="alertVariant" :alertParams="alertParams" :alertCount="alertCount" ref="cgLocAlert" :alertMessage="alertMessage"></cg-localized-alert>
	
		<div class="px-4" :class="canAccessToList? 'mb-0' : 'mb-4'">
			<b-card-title class="text-truncate d-block" :class="canAccessToList? 'mb-4' : 'mb-0'">
				{{ (selectedCompany) ? $t('Navbar.Company') + ': ' + selectedCompanyName : $t('Navbar.Company') }}</b-card-title>
			<div v-if="!selectedCompany">
				<!-- New Company button -->
				<b-row class="my-4" align-h="end">
					<b-col cols="auto">
						<b-button v-if="$checkPermission('create-company')" @click="showCreateCompanyModal" size="sm" variant="primary" class="mb-3">
						<font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'plus']"/>{{ $t('Company.NewCompany') }}</b-button>
					</b-col>
				</b-row>
				<b-card no-body class="mb-4" v-if="canAccessToList">
					<b-tabs v-model="tabIndex" card>
						<b-tab class="company-list" no-body v-for="(type, index) in companyTabModel" :title="$t(type.title)" :key="index">

							<b-list-group>
								<b-list-group-item v-for="(company, index) in filteredCompanies" :key="index" @click="showCompany(company)"
									class="flex-column align-items-start pointer" :class="{'archived-company': company.archived == 1}" :disabled="!!company.archived">
									<b-row class="d-flex w-100 justify-content-between">
										<b-col cols="auto">
											<img class="small" :src="'/api/company/' + company.company_id + '/logo?' + Date.now()" :alt="$t('General.CompanyLogo')" />
										</b-col>
										<b-col align-self="center" class="d-block text-truncate">
											<h5 class="d-block text-truncate mb-1">{{company.name}}</h5>
										</b-col>
										<b-col cols="auto" align-self="center" class="btn-unarchive px-0" v-if="$checkPermission('modify-company')">
											<b-button v-if="!!company.archived" variant="primary" class="btn-action float-right" @click="unarchiveCompany(company)">
												<font-awesome-icon :icon="['fas', 'trash-restore-alt']" />
												{{ $t('Company.Unarchive') }}</b-button>
										</b-col>
									</b-row>
								</b-list-group-item>
						
								<span class="no-company-message" v-if="filteredCompanies && !filteredCompanies.length">{{ $t('Company.NoAvailableCompany') }}</span>
							</b-list-group>
				
						</b-tab>
					</b-tabs>
				</b-card>
			</div>

			<div v-else>
				<b-row>
					<b-col sm="auto" v-if="canAccessToList">
						<small @click="selectedCompany = null" class="mt-2 font-italic back-link">
							<font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-1 " />{{$t('Company.CompanyList')}}
						</small>
					</b-col>
				</b-row>
				
				<b-row align-h="between" class="mt-4">
					<b-col cols="auto">
						<b-button variant="danger" id="deleteCompanyButton" class="btn-action" v-if="$checkPermission('delete-company')" :disabled="!!hasRunningCampaign"
							@click="$bvModal.show('confirmDeleteCompany')">{{ $t('General.Delete') }}</b-button>

						<font-awesome-icon id="deleteCompanyDisabledTooltip" :icon="['fas', 'info-circle']" class="ml-2" v-if="hasRunningCampaign"/>
						<b-tooltip class="mt-2 mr-2" triggers="hover" right target="deleteCompanyDisabledTooltip">
							<span v-html="$t('Company.PreserveCompanyTooltip')"></span>
						</b-tooltip>
					</b-col>
					<b-col cols="auto">
						<b-button variant="secondary" :disabled="!companyDataHasChanged" class="btn-action mr-4" @click="restoreDefaultCompany">{{ $t('General.Cancel') }}</b-button>
						<b-button variant="primary" :disabled="!companyDataHasChanged" class="btn-action" @click="updateCompany">{{ $t('General.Save') }}</b-button>
					</b-col>
				</b-row>

				<b-modal id="confirmDeleteCompany" content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
					bodyClass="text-center" @show="userHasConfirmed = ''" footerClass='p-2' :centered='true' 
					:okDisabled="userHasConfirmed != 'OK'" :title="$t('Account.ConfirmDeleteTitle')">
					<p class="my-2 text-left" v-html="$t('General.ConfirmNotReversible')"></p>
					<input class="text-center mt-4" v-model="userHasConfirmed" />
					<b-form-checkbox v-model="keepData" :value="1" :unchecked-value="0" class="text-right mt-4">
						{{ $t('Company.KeepDataInfo') }}
					</b-form-checkbox>
					<template #modal-footer>
						<b-button variant="secondary" class="btn-action" @click="$bvModal.hide('confirmDeleteCompany')">{{ $t('General.Cancel') }}</b-button>
						<div v-if="keepData">
							<b-button :disabled="userHasConfirmed != 'OK'" size='md' buttonSize='md' variant="danger" class="text-uppercase" @click="deleteCompany">{{ $t('General.Ok') }}</b-button>
						</div>
						<div v-else>
							<b-button :disabled="userHasConfirmed != 'OK'" v-b-modal.irreversibleDeleteModal size='md' buttonSize='md' variant="danger" class="text-uppercase">{{ $t('General.Ok') }}</b-button>
						</div>
					</template>
				</b-modal>

				<!-- Modal before delete Company without keeping data  -->
				<b-modal id="irreversibleDeleteModal" content-class="shadow" size='md' buttonSize='md' okVariant='danger' hide-header hide-footer
					bodyClass="text-center" footerClass='p-2' :centered='true'>
					<h3>{{ $t('Company.ConfirmDeleteCompanyWarning') }}</h3>
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="my-3 exclamation-triangle"/>
					<p style="font-bold">{{ $t("Company.ConfirmDeleteCompany") }}</p>
					<b-row align-h="between" class="cg-modal-footer mt-4 pt-3 pb-1">
						<b-col>
							<b-button @click="$bvModal.hide('irreversibleDeleteModal')">{{ $t('Company.ConfirmDeleteCompanyBack') }}</b-button>
						</b-col>
						<b-col>
							<b-button variant="danger" @click="deleteCompany">{{ $t('Company.ConfirmDeleteCompanyProceed') }}</b-button>
						</b-col>
					</b-row>
				</b-modal>

				<!-- Modal for unsaved info -->
				<b-modal id="before-close" content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
					bodyClass="text-center" footerClass='p-2' :centered='true' @ok="changeRoute" :title="$t('General.UnsavedInfo')">
					<p class="my-2 text-left">{{ $t("General.UnsavedInfoText") }}</p>
				</b-modal>

				<b-card class="mt-4" no-body v-if="selectedCompany">

					<b-tabs card>

						<b-tab class="mt-4 mx-3" :title="$t('Company.CompanyDetails')" active>
							<b-row class="d-flex w-100 justify-content-between">
								<b-col cols="auto">
									<img class="big mb-4" :src="companyLogoUrl" :alt="$t('General.CompanyLogo')" />
								</b-col>
								<b-col>
									<b-form-group class="form-align-self-center" label-size="md" label-cols-lg="2" label-cols="4" label-align-sm="right" 
										v-for="(attr, index) in generalAttribute" :key="index" :label="$tc(attr.label)" :label-for="companyName+'_'+attr.id">
										<b-form-input v-cloak v-if="textTypes.includes(attr.type)"
											:disabled="attr.update_disabled" 
											:id="companyName+'_'+attr.id" 
											:placeholder="$t(attr.placeholder)" 
											:type="attr.type"
											@input="selectedCompany[attr.id]=modifyInput($event, attr)"
											@change="validate($event, attr)"
											autocomplete="new-password"
											v-model="selectedCompany[attr.id]"
											:class="{'form-field-error': requiredEmpty.includes(attr) || invalidField.includes(attr) || attr.error }">	
										</b-form-input>
										<b-form-select v-if="attr.type=='select'" 
											v-model="selectedCompany[attr.id]"
											:disabled="attr.update_disabled" 
											:options=" attr.options" 
											:required="attr.required"
											autocomplete="new-password"
											:id="companyName+'_'+attr.id"
											:class="{'form-field-error': requiredEmpty.includes(attr) || invalidField.includes(attr)}">	
										</b-form-select>
									</b-form-group>
									<b-form-group class="form-align-self-center mt-3" label-size="md" label-cols-lg="2" label-cols="4" label-align-sm="right" label-for="defaultLandingPage" :label="$t('Company.DefaultLandingPage')" 
										v-if="$checkPermission('modify-company, view-landing-page')">
										<b-form-select id="defaultLandingPage" v-model="selectedCompany.landing_page_id"
											:options="lpOptions">	
										</b-form-select>
									</b-form-group>

									<b-form-group class="form-align-self-center mt-3" label-size="md" label-cols-lg="2" label-cols="4" label-align-sm="right" :label="$t('Company.Logo')" 
										v-if="$checkPermission('modify-company-logo')">
										<b-form-file v-model="logoFile" size="md" :placeholder="$t('Company.UploadLogo')"
											accept="image/*" id="logoFile" ref="logoFile" @input="uploadLogo"></b-form-file>
									</b-form-group>

									<b-form-group label-size="md" label-cols-lg="2" label-cols="4" label-align-sm="right" :label="$t('Company.Archive')">
										<b-form-checkbox :disabled="!$checkPermission('modify-company')" switch size="lg" class="mt-1"
											id="companyArchived"
											v-model="selectedCompany.archived"
											:value="1" :unchecked-value="0"
											@change="updateCompanyArchived">
											<span class="sr-only">{{ $t('Company.Archive') }}</span>
										</b-form-checkbox>
									</b-form-group>

									<b-form-group label-size="md" label-cols-lg="2" label-cols="4" label-align-sm="right" :label="$t('Company.Activate')">
										<b-form-checkbox :disabled="!$checkPermission('modify-company') || selectedCompany.archived == 1" switch size="lg" class="mt-1"
											id="companySuspended"
											v-model="suspended"
											:value="1" :unchecked-value="0">
											<span class="sr-only">{{ $t('Company.Suspend') }}</span>
										</b-form-checkbox>
									</b-form-group>

									<b-form-group label-size="md" label-cols-lg="2" label-cols="4" label-align-sm="right" :label="$t('Company.ReportingEnabled', { symbol: '*' })">
										<b-form-checkbox :disabled="!$checkPermission('modify-company')" switch size="lg" class="mt-1"
											id="reportingEnabled"
											v-model="selectedCompany.reporting_enabled"
											:value="1" :unchecked-value="0">
											<span class="sr-only">{{ $t('Company.ReportingEnabled') }}</span>
										</b-form-checkbox>
									</b-form-group>

									<b-form-group label-size="md" label-cols-lg="2" label-cols="4" label-align-sm="right" :label="$t('Company.HideRiskEvolution')">
										<b-form-checkbox :disabled="!$checkPermission('modify-company')" switch size="lg" class="mt-1"
											id="hideRiskEvolution"
											v-model="selectedCompany.hide_evolution"
											:value="1" :unchecked-value="0">
											<span class="sr-only">{{ $t('Company.HideRiskEvolution') }}</span>
										</b-form-checkbox>
									</b-form-group>
								</b-col>						
							</b-row>

							<div class="footer-message">
								{{ reportEnabledLegend }}
							</div>
						</b-tab>

						<!-- Company Account assignment -->
						<b-tab class="mt-4 mx-3" v-if="$checkPermission('list-account')" :title="$t('Company.AssociatedAccounts')" >
							<b-card no-body class="my-4 px-3" v-if="accountList && accountList.length > 0">
								<b-row v-for="(account, index) in accountList" :key="index" :id="'template-' + index" class="separator py-2">
									<b-col class="line-height-30">{{ account.displayname }}</b-col>
									<b-col cols="auto" align-self="center" class="line-height-30"> {{ accountRoleLabel(account) }} </b-col>
								</b-row>
							</b-card>

							<p class="text-center my-4 px-3" v-else>{{ $t('Company.NoAssociatedAccounts') }}</p>
						</b-tab>

						<b-tab class="mt-4 mx-3" :title="$t('Company.OrgLevels')">
							<b-card-text class="mb-4">{{ $t('Company.SetOrgLevels') }}</b-card-text>

							<b-form-group class="form-align-self-center" label-size="md" label-cols-sm="2" label-cols-md="4" label-cols-lg="2" label-align-sm="right" 
								v-for="(attr, i) in orgAttribute" :key="i" :label="$t(attr.label)" :label-for="companyName+'_'+attr.id">
								<b-row>
									<b-col>
										<b-form-input :id="companyName+'_'+attr.id"
											:disabled="attr.update_disabled"
											v-model="selectedCompany[attr.id]"
											:required="attr.required"
											:placeholder="$t(attr.placeholder)"
											:class="{'form-field-error': requiredEmpty.includes(attr) || invalidField.includes(attr)}"
											autocomplete="new-password"
											class="padding-right-50">
										</b-form-input>
									</b-col>
									<b-col :class="{ 'first-line' : i===0 }" class="checkbox-col-fixed">
										<span v-if="i==0">{{ $t('General.Display') }}</span>
										<b-form-checkbox 
											class="org-checkbox"
											:disabled="attr.update_disabled"
											button-variant="info"
											:unchecked-value="0"
											:checked="[1,2].includes(selectedCompany[attr.visible])"
											@change="(checked) => selectedCompany[attr.visible] = checked ? 1 : 0"
											>
										</b-form-checkbox>
									</b-col>
									<b-col :class="{ 'first-line' : i===0 }" class="checkbox-col-fixed">
										<span v-if="i==0">{{ $t('General.Filter') }}</span>
										<b-form-checkbox
											class="org-checkbox"
											:value="2" 
											:unchecked-value="1"
											:disabled="!selectedCompany[attr.visible]"
											v-model="selectedCompany[attr.visible]">
										</b-form-checkbox>
									</b-col>
								</b-row>
							</b-form-group>
						</b-tab>
						
						<b-tab class="mt-4 mx-3" v-if="$checkPermission('modify-company,modify-company-campaign')" :title="$t('Company.CampaignConfig')" >
							<b-card-text class="mb-4">{{ $t('Company.SetCampaignParameters') }} 
								<font-awesome-icon :icon="['fas', 'info-circle']" size="lg" class="ml-2 pointer color-secondary" @click="$bvToast.show('default-campaign-values')"></font-awesome-icon>
							</b-card-text>

							<b-form-group class="form-align-self-center" label-size="md" label-cols-lg="3" label-cols="5" label-align-sm="left" label-align-lg="right" 
								v-for="(attr, i) in campaignAttributes" :key="i" label-cols-sm="12" :label="$t(attr.label)" :label-for="companyName+'_'+attr.id">
								<b-form-input v-cloak v-if="textTypes.includes(attr.type)"
									:id="companyName+'_'+attr.id" 
									:disabled="attr.update_disabled" 
									v-model="selectedCompany[attr.id]" 
									:required="attr.required"
									:type="attr.type"
									:min="attr.min" 
									:max="attr.max" 
									:step="attr.step"
									:placeholder="$t(attr.placeholder)"
									:class="{ 'form-field-error': requiredEmpty.includes(attr) || invalidField.includes(attr) }"
									autocomplete="new-password">
								</b-form-input>

								<b-input-group v-if="attr.type=='append'">
									<b-form-input v-cloak
										:id="companyName+'_'+attr.id" 
										:disabled="attr.update_disabled" 
										v-model="selectedCompany[attr.id]" 
										:required="attr.required"
										type="number"
										:min="attr.min" 
										:max="attr.max" 
										:step="attr.step"
										:placeholder="$t(attr.placeholder)"
										:class="{ 'form-field-error': requiredEmpty.includes(attr) || invalidField.includes(attr) }"
										autocomplete="new-password">
									</b-form-input>
									<template v-slot:append>
										<b-input-group-text>%</b-input-group-text>
									</template>
								</b-input-group>

								<b-form-select v-if="attr.type=='select'"
									:disabled="attr.update_disabled" 
									v-model="selectedCompany[attr.id]"
									:required="attr.required"
									:options=" attr.options" 
									autocomplete="new-password"
									:id="companyName+'_'+attr.id"
									:class="{ 'form-field-error': requiredEmpty.includes(attr) || invalidField.includes(attr) }">	
								</b-form-select>
							</b-form-group>

							<!-- Enable SMS Templates for this Company -->
							<b-form-group label-size="md" label-cols-sm="9" label-cols-lg="3" label-cols="5" label-align-sm="right" :label="$t('Company.SMSEnabled')" class="mt-2">
								<b-form-checkbox :disabled="!$checkPermission('modify-company')" switch size="lg" class="mt-1"
									id="smsEnabled"
									v-model="selectedCompany.sms_enabled"
									:value="1" :unchecked-value="0">
									<span class="sr-only">{{ $t('Company.SMSEnabled') }}</span>
								</b-form-checkbox>
							</b-form-group>

							<!-- Enable Training Remediation Templates for this Company -->
							<b-form-group label-size="md" label-cols-sm="9" label-cols-lg="3" label-cols="5" label-align-sm="right" :label="$t('Company.EnableRemediationTraining')">
								<b-form-checkbox :disabled="!$checkPermission('modify-company')" switch size="lg" class="mt-1"
									id="trainingRemediation"
									v-model="selectedCompany.enable_rem_training"
									:value="1" :unchecked-value="0">
									<span class="sr-only">{{ $t('Company.EnableRemediationTraining') }}</span>
								</b-form-checkbox>
							</b-form-group>

							<!-- Enable QR code Remediation Templates for this Company -->
							<b-form-group label-size="md" label-cols-sm="9" label-cols-lg="3" label-cols="5" label-align-sm="right" :label="$t('Company.EnableRemediationQRCode')">
								<b-form-checkbox :disabled="!$checkPermission('modify-company')" switch size="lg" class="mt-1"
									id="qrCodeRemediation"
									v-model="selectedCompany.enable_rem_qrcode"
									:value="1" :unchecked-value="0">
									<span class="sr-only">{{ $t('Company.EnableRemediationQRCode') }}</span>
								</b-form-checkbox>
							</b-form-group>

							<!-- Enable USB Attack Remediation Templates for this Company -->
							<b-form-group label-size="md" label-cols-sm="9" label-cols-lg="3" label-cols="5" label-align-sm="right" :label="$t('Company.EnableRemediationUSB')">
								<b-form-checkbox :disabled="!$checkPermission('modify-company')" switch size="lg" class="mt-1"
									id="usbRemediation"
									v-model="selectedCompany.enable_rem_usb"
									:value="1" :unchecked-value="0">
									<span class="sr-only">{{ $t('Company.EnableRemediationUSB') }}</span>
								</b-form-checkbox>
							</b-form-group>

							<!-- Enable Assessment Campaign for this Company -->
							<b-form-group label-size="md" label-cols-sm="9" label-cols-lg="3" label-cols="5" label-align-sm="right" :label="$t('Company.AssessmentCampaign')">
								<b-form-checkbox :disabled="!$checkPermission('modify-company')" switch size="lg" class="mt-1"
									id="assessmentCampaign"
									v-model="selectedCompany.assessment_campaign"
									:value="1" :unchecked-value="0">
									<span class="sr-only">{{ $t('Company.AssessmentCampaign') }}</span>
								</b-form-checkbox>
							</b-form-group>

							<!-- Enable embed images -->
							<b-form-group label-size="md" label-cols-sm="9" label-cols-lg="3" label-cols="5" label-align-sm="right" :label="$t('Company.EmbedImage')">
								<b-form-checkbox :disabled="!$checkPermission('modify-company')" switch size="lg" class="mt-1"
									v-model="selectedCompany.embed_images"
									:value="1" :unchecked-value="0">
									<span class="sr-only">{{ $t('Company.EmbedImage') }}</span>
								</b-form-checkbox>
							</b-form-group>

							<!-- Enable Auto-approve Campaign for this Company -->
							<b-form-group label-size="md" label-cols-sm="9" label-cols-lg="3" label-cols="5" label-align-sm="right" :label="$t('Company.AutoApproveCampaign')">
								<b-form-checkbox :disabled="!$checkPermission('modify-company')" switch size="lg" class="mt-1"
									id="autoApproveCampaign"
									v-model="selectedCompany.campaign_auto_approve"
									:value="1" :unchecked-value="0">
									<span class="sr-only">{{ $t('Company.AutoApproveCampaign') }}</span>
								</b-form-checkbox>
							</b-form-group>
						</b-tab>
					</b-tabs>					
				</b-card>

			</div>
		</div>
	</b-container>
</template>

<script>
import companyService from "@/services/company.service.js";
import accountService from '../services/account.service';
import syspropService from '../services/sysprop.service';
import Vue from 'vue';
import { isEmail } from 'validator'
import landingpageService from '../services/landingpage.service';
import { Campaign, LandingPageScope } from '../../../server/common/constants';

export default {
	name: 'cg-company',
	data() {
		return {
			accountList: [],
			companyList: [],
			inProgress: false,
			alertMessage: '',
			alertVariant: 'success',
			alertParams: null,
			alertCount: 1,
			logoFile: null,
			accountValidDomains: null,
			untouchEmailDomain: null,
			imgTimestamp: Date.now(),
			textTypes: ['text', 'password', 'email', 'number', 'search', 'url', 'tel', 'time', 'range', 'color'],
			data: {
				name: "company-modal",
				title: 'Company.CreateCompany',
				label: 'Company.CreateCompany',
				item: {},
				submitHandler: "createCompany",
				fields:[
					{   id: "name",
						label: 'Company.Name',
						type: "text",
						section: 'general',
						required: true,
						display: true,
						placeholder: 'Company.Name',
						update_disabled: !this.$checkPermission('modify-company,modify-company-labels')
					},
					{   id: "smtp_host",
						label: 'Company.SMTPHost',
						type: "text",
						section: 'general',
						required: true,
						display: true,
						placeholder: 'Company.SMTPHostDescription',
						update_disabled: !this.$checkPermission('modify-company,modify-company-smtp')
					},
					{   id: "smtp_username",
						label: 'Company.SMTPUsername',
						section: 'general',
						type: "text",
						display: true,
						placeholder: 'Company.SMTPUsername',
						update_disabled: !this.$checkPermission('modify-company,modify-company-smtp')
					},
					{   id: "smtp_password",
						label: 'Company.SMTPPassword',
						section: 'general',
						type: "password",
						display: true,
						placeholder: 'Company.SMTPPassword',
						update_disabled: !this.$checkPermission('modify-company,modify-company-smtp')
					},
					{   id: "email_domain",
						label: 'Company.EmailDomain',
						section: 'general',
						type: "text",
						display: true,
						required: true,
						placeholder: '@company_a.com, @company_b.com',
						validator: this.isValidDomainSet,
						errorAlertMessage: '',
						errorAlertParams: this.getAlertParams,
						errorAlertCount: this.getAlertCount,
						update_disabled: !this.$checkPermission('modify-company,modify-company-email-domain')
					},
					{   id: "default_language",
						label: 'Company.DefaultLanguage',
						type: "select",
						section: 'general',
						options: [],
						display: true,
						placeholder: 'Company.DefaultLanguage',
						update_disabled: !this.$checkPermission('modify-company,modify-company-lang')
					},
					{   id: "country",
						label: 'General.Country',
						type: "select",
						section: 'general',
						options: [],
						required: true,
						display: true,
						update_disabled: !this.$checkPermission('modify-company')
					},
					{   id: "org_1",
						label: 'Company.Org1',
						type: "text",
						section: 'labels',
						display: true,
						placeholder: 'Company.Org1',
						visible: 'use_org_1',
						update_disabled: !this.$checkPermission('modify-company,modify-company-labels')
					},
					{   id: "org_2",
						label: 'Company.Org2',
						type: "text",
						section: 'labels',
						display: true,
						placeholder: 'Company.Org2',
						visible: 'use_org_2',
						update_disabled: !this.$checkPermission('modify-company,modify-company-labels')
					},
					{   id: "org_3",
						label: 'Company.Org3',
						type: "text",
						section: 'labels',
						display: true,
						placeholder: 'Company.Org3',
						visible: 'use_org_3',
						update_disabled: !this.$checkPermission('modify-company,modify-company-labels')
					},
					{   id: "org_4",
						label: 'Company.Org4',
						type: "text",
						section: 'labels',
						display: true,
						placeholder: 'Company.Org4',
						visible: 'use_org_4',
						update_disabled: !this.$checkPermission('modify-company,modify-company-labels')
					},
					{   id: "campaign_duration",
						label: 'Company.CampaignDuration',
						type: "text",
						configKey: 'duration',
						section: 'campaign',
						display: true,
						placeholder: 'Company.CampaignDuration',
						update_disabled: !this.$checkPermission('modify-company,modify-company-campaign')
					},
					{   id: "send_email_duration",
						label: 'Company.SendEmailDuration',
						type: "text",
						configKey: 'sendEmailPhaseDuration',
						section: 'campaign',
						display: true,
						placeholder: 'Company.SendEmailDuration',
						update_disabled: !this.$checkPermission('modify-company,modify-company-campaign')
					},
					{   id: "n_campaign_templates",
						label: 'Company.N_CampaignTemplates',
						type: "number",
						configKey: 'proposedTemplates',
						section: 'campaign',
						display: true,
						placeholder: 'Company.N_CampaignTemplates',
						update_disabled: !this.$checkPermission('modify-company,modify-company-campaign')
					},
					{   id: "n_min_campaign_templates",
						label: 'Company.N_MinCampaignTemplates',
						type: "number",
						configKey: 'minimumTemplates',
						section: 'campaign',
						display: true,
						placeholder: 'Company.N_MinCampaignTemplates',
						update_disabled: !this.$checkPermission('modify-company,modify-company-campaign')
					},
					{   id: "template_specific_assign_rate",
						label: 'Company.TemplateSpecificAssignRate',
						type: "append",
						min: 0,
						max: 100,
						step: 1,
						configKey: 'templateSpecificAssignRate',
						section: 'campaign',
						display: true,
						placeholder: 'Company.TemplateSpecificAssignRate',
						update_disabled: !this.$checkPermission('modify-company,modify-company-campaign')
					},
					{   id: "n_onboarding_campaigns",
						label: 'Company.N_OnboardingCampaigns',
						type: "select",
						options: [{ value: 1, text: "1" }, { value: 3, text: "3" }],
						configKey: 'onboardingPhaseLength',
						section: 'campaign',
						display: true,
						placeholder: 'Company.N_OnboardingCampaigns',
						update_disabled: !this.$checkPermission('modify-company,modify-company-campaign')
					}
				],
			},
			config: null,
			selectedCompany: null,
			defaultCompany: null,
			selectedCompanyName: null,
			orgLevels: [],
			requiredEmpty: [],
			invalidField: [],
			invalidDomain: [],
			canAccessToList: this.$checkPermission('list-company') && !this.$route.params.showCurrentCompany,
			lpOptions: [],
			reportEnabledLegend: this.$t('Company.ReportingEnabledLegend', { symbol: '*' }),
			companyDataHasChanged: false,
			companyTabModel: [
				{ title: 'Company.ShowActiveCompanyInfo' },
				{ title: 'Company.ShowArchivedCompanyInfo' }
			],
			tabIndex: 0,
			hasRunningCampaign: null,
			userHasConfirmed: '',
			keepData: null,
			supportedCountry: null
		};
	},
	watch:{
		selectedCompany: {
			handler(value) {
				if(!value) return;
				let diff = Object.keys(value).filter((a) => { return value[a] != this.defaultCompany[a]; });
				if(diff && diff.length) {
					this.companyDataHasChanged = true;
				}
			},
			deep: true
		}
	},
	beforeRouteLeave(to, from, next) {
		if (this.companyDataHasChanged) {
			this.to = to;
			this.$bvModal.show('before-close');
		} else {
			next();
		}
	},
	computed: {
		companyName() {
			return this.selectedCompany.name;
		},
		companyLogoUrl: function() {
			return '/api/company/' + this.selectedCompany.company_id + '/logo?'+ this.imgTimestamp;
		},
		generalAttribute() {
			return this.data.fields.filter((attr) => { return attr.section == 'general' });
		},
		orgAttribute() {
			return this.data.fields.filter((attr) => { return attr.section == 'labels' });
		},
		campaignAttributes() {
			return this.data.fields.filter((attr) => { return attr.section == 'campaign' });
		},
		createCompanyFields() {
			var createData = {};
			Object.assign(createData,this.data);
			createData.fields = this.data.fields.filter((attr) => { return attr.section == 'general' });
			return createData;
		},
		suspended: {
			get () {
				return this.selectedCompany.suspended ? 0 : 1;
			},
			set(value) {
				this.selectedCompany.suspended = value ? 0 : 1;
			}
		},
		filteredCompanies() {
			return this.companyList.filter((c) => !!c.archived == !!this.tabIndex);
		}
	},
	methods: {
		changeRoute() {
			this.companyDataHasChanged = false;
			this.$router.push(this.to.path);
		},
		toLowerCase(string) {
			return string.toLowerCase();
		},
		modifyInput(event, data) {
			let inputFunction = data.input_type;
			return inputFunction? inputFunction(event) : event;
		},
		accountRoleLabel(account) {
			return account && account.role_label? account.role_label.replace(/(\s*)(,\s*)/mg, ", ") : '';
		},
		showCreateCompanyModal() {
			console.debug("Company - showCreateCompanyModal");
			this.data.item = {};
			this.data.item.country = 'IT';
			this.data.item.smtp_host = this.config.defaults.smtp_host;
		
			this.$bvModal.show(this.data.name);
		},
		createCompany(data) {
			console.debug("Company - createCompany event", data);
			let body = {
				name: data.name,
				smtp: { "host": data.smtp_host, "password": data.smtp_password, "username": data.smtp_username }, 
				orgLevels: { "org_1": data.org_1, "org_2": data.org_2, "org_3": data.org_3, "org_4": data.org_4 },
				default_language: data.default_language,
				country: data.country,
				email_domain: data.email_domain.toLowerCase()
			}

			this.inProgress = true;
			
			companyService.createCompany(body).then((res) => {
				console.debug("Company - createCompany success", res);
				this.alertVariant = 'success';
				this.alertMessage = 'Company.CreatedCompany';
				this.$account.companies.push(res.data);
				this.$account.companies = this.$account.companies.sort((a, b) => { return ('' + a.name).localeCompare('' + b.name); });
				if(!localStorage.getItem('cg-company')) {
					localStorage.setItem('cg-company', res.data.company_id);
				}

				if(this.$checkPermission('list-company-all')) {
					this.companyList.push(res.data);
					this.companyList.sort((a, b) => { return ('' + a.name).localeCompare('' + b.name); });
				}

				Vue.prototype.$account.permissions.push({ company_id: res.data.company_id, permissions: res.data.permissions });
			}).catch((error) => {
				console.error("Company - createCompany error", error);
				if (error.response.status == 409) {
					this.alertMessage = 'Company.CompanyAlreadyExists';
					this.alertParams = { param: body.name };
					this.dismissSecs = 5;
				} else {
					this.alertMessage = 'Company.CreateCompanyError';
				}
				this.alertVariant = 'danger';
			}).finally(() => {
				this.inProgress = false;
				this.$refs.cgLocAlert.showAlert();
			});
		},
		async uploadLogo() {
			if(!this.logoFile) {
				return
			}
			if (this.logoFile.size > 1024 * 64) {
				this.alertVariant = 'danger';
				this.alertMessage = 'Company.LogoFileSize';
				this.$refs.cgLocAlert.showAlert();
				return;
			}
			this.loading = true;
			let logoData = new FormData();
			logoData.append('logofile', this.logoFile);
			await companyService.uploadLogo(this.selectedCompany.company_id, logoData);
			this.imgTimestamp = Date.now();
			this.logoFile = null;
			if(this.selectedCompany.company_id == localStorage.getItem('cg-company')) {
				this.$emit('companyUpdated', { name: this.selectedCompany.name, company_id: this.selectedCompany.company_id });
			}
		},
		showCompany(company) {
			if(this.tabIndex) {
				return;
			}
			this.requiredEmpty = [];
			this.invalidField = [];
			if(this.$checkPermission('list-account')) {
				if(this.accountList.length) {
					this.getAssociatedAccountList(company.company_id);
				} else {
					this.getAccountList(company.company_id);
				}
			}
			this.getCompanyData(company.company_id);
		},
		async unarchiveCompany(company) {
			this.inProgress = true;
			await companyService.changeCompanyArchivedStatus(company.company_id, 0).then((res) => {
				console.debug("Company - updateCompany - unarchived success", res);
				this.alertMessage = 'Company.UpdatedCompany';
				this.alertVariant = 'success';
				Vue.set(this.companyList.find(c => c.company_id === company.company_id), 'archived', 0);
				this.$emit('companyToggleArchive', { company_id: company.company_id, archived: 0 });
				if(this.filteredCompanies && !this.filteredCompanies.length) {
					this.tabIndex = 0;
				}
				if(!this.$checkPermission('list-company-all')) {
					this.companyList = this.companyList.filter((c) => 
						c.company_id === localStorage.getItem("cg-company") || c.archived == 1 );
				}
			}).catch((error) => {
				console.error("Company - updateCompany - unarchived error", error);
				this.alertMessage = 'Company.UpdatedCompanyError';
				this.alertVariant = 'danger';
			}).finally(() => {
				this.inProgress = false;
				this.$refs.cgLocAlert.showAlert();
			});
		},
		async getAssociatedAccountList(company_id) {
			this.inProgress = true;
			let assignedAccount = await companyService.getRolesString(company_id);
			let tempList = [...this.accountList];
			tempList.forEach((account) => {
				account.role_label = null;
				assignedAccount.data.forEach((assigned) => {
					if(account.account_id == assigned.account_id) {
						account.role_label = assigned.role_label;
					}
				});
			});

			this.accountList = tempList.filter((a) => { return a.role_label != null; });
			this.inProgress = false;
		},
		async getCompanyList() {
			this.inProgress = true;
			let result = await companyService.getCompanyList();

			if(!this.$checkPermission('list-company-all')) {
				result.data = result.data.filter((c) => 
					c.company_id === localStorage.getItem("cg-company") || c.archived == 1 );
			}
			this.companyList = result.data.sort((a, b) => { return ('' + a.name).localeCompare(b.name); });
			this.inProgress = false;
		},
		async getAccountList(company_id) {
			this.inProgress = true;
			let result = await accountService.getAccounts(this.$account.account_id, '', null, null, 'displayname', false);
			this.accountList = result.data.accounts;
			this.inProgress = false;

			await this.getAssociatedAccountList(company_id);
		},
		// Retrieve additional Company Data 
		async getCompanyData(company_id) {
			this.inProgress = true;
			let result = await companyService.getCompany(company_id);

			this.defaultCompany = Object.assign({}, result.data);

			let runningCampaignList = await companyService.getCampaignList(company_id, { status: Campaign.Status.Running });
			this.hasRunningCampaign = runningCampaignList && runningCampaignList.data? runningCampaignList.data.length > 0 : false;

			// Convert the template_specific_assign_rate value from decimal to percentage
			if (this.defaultCompany.template_specific_assign_rate !== null) {
				this.defaultCompany.template_specific_assign_rate = this.defaultCompany.template_specific_assign_rate * 100;
			}
			
			if(this.defaultCompany.smtp) {
				var smtp = JSON.parse(this.defaultCompany.smtp);
				//this.selectedCompany.smtp = smtp
				this.defaultCompany.smtp_host = smtp.host? smtp.host : null; 
				this.defaultCompany.smtp_username = smtp.username? smtp.username : null; 
				this.defaultCompany.smtp_password = smtp.password? smtp.password : null; 
			}
			if(!this.defaultCompany.n_onboarding_campaigns) {
				this.defaultCompany.n_onboarding_campaigns = this.config.defaults.campaign.onboardingPhaseLength;
			}

			if(this.defaultCompany.email_domain && !this.$checkPermission('list-company-all')) {
				// Only Super Admin can manage the whole email domains list, other Accounts can only act on their own subset
				let accountEmailDomain = this.defaultCompany.email_domain.split(/,| /).filter( dom => { return  dom != ''; } );
				this.untouchEmailDomain = accountEmailDomain && accountEmailDomain.filter( dom => { return !this.accountValidDomains.includes(dom.trim()); } );
				this.defaultCompany.email_domain = accountEmailDomain && accountEmailDomain.filter(el => !this.untouchEmailDomain.includes(el)).join(', ');
			}
	
			this.selectedCompanyName = this.defaultCompany.name;
			this.restoreDefaultCompany();

			if (this.$checkPermission('view-landing-page')) {
				let lPages = await landingpageService.getLandingPages(this.selectedCompany.company_id, LandingPageScope.GlobalAndCompany);
				// empty the array of landing pages options
				this.lpOptions = [];

				this.lpOptions.push({ value: null, text: this.$t('Company.DefaultLandingPagePlaceholder') });
				lPages.data.sort((a, b) => { return (a.scope > b.scope); })
                           .sort((a, b) => { return (a.scope == b.scope) && (a.page_id > b.page_id); })
				lPages.data.forEach(lp => {
                    let lpname = (lp.scope == LandingPageScope.Global) ? this.$i18n.t('Template.LandingPageNameGlobal', { id: lp.page_id, name: lp.name }): this.$i18n.t('Template.LandingPageName', { id: lp.page_id, name: lp.name });
                    this.lpOptions.push( { value: lp.page_id, text: lpname } );
				});
			}
			this.inProgress = false;
		},
		validate(value, field) {
			field.error = false;
			if(typeof field.validator == 'function' && value != '') {
				this.formValid = field.validator(value);
				if(!this.formValid) {
					field.error = true;
					if(field.errorAlertMessage) {
						this.alertMessage = field.errorAlertMessage;
						if(field.errorAlertParams && typeof field.errorAlertParams == 'function') {
							this.alertParams = field.errorAlertParams();
						}
						if(field.errorAlertCount && typeof field.errorAlertCount == 'function') {
							this.alertCount = field.errorAlertCount();
						}
						this.alertVariant = 'danger';
						this.$refs.cgLocAlert.showAlert();
					} 
				}
			}
		},
		isValidDomainSet(value) {
			if(!value) {
				return;
			}

			// Super Admin shall only insert valid email addresses
			let domains = value.split(/,| /).filter( dom => { return  dom != ''; } );
			this.invalidDomain = domains.filter( dom => { return !isEmail('test' + dom.trim()); } );

			if(this.invalidDomain.length > 0) {
				Vue.set(this.data.fields.find(i => i.id === 'email_domain'), 'errorAlertMessage', 'Company.InvalidEmailDomainValue');
				return false;
			}

			if(this.$checkPermission('list-company-all')) {
				return true;
			}

			// Other Account role must insert allowed email domains only
			this.invalidAccountDomain = domains.filter( dom => { return !this.accountValidDomains.includes(dom.trim()); } );

			if(this.invalidAccountDomain.length > 0) {
				Vue.set(this.data.fields.find(i => i.id === 'email_domain'), 'errorAlertMessage', 'Account.InvalidEmailDomain');
				return false;
			}

			return true;
		},
		getAlertParams() {
			return this.invalidDomain.length > 0 ? { domain: this.invalidDomain.join(', ') } : 
					this.invalidAccountDomain.length > 0 ? { domain: this.$account.email_domain } : {};
		},
		getAlertCount() {
			return this.invalidDomain.length > 0 ? this.invalidDomain.length : this.accountValidDomains.length;
		},
		updateCompany() {
			let body = {}
			this.requiredEmpty = this.data.fields.filter((attr) => {
				return attr.required && (!this.selectedCompany[attr.id] || this.selectedCompany[attr.id] == "");
			});
			this.invalidField = [];
			this.invalidDomain = [];
			
			if(this.requiredEmpty.length > 0) {
				var emptyFields = this.requiredEmpty.map((field) => { return field.label }); 
				this.alertVariant = 'danger';
				this.alertMessage = 'Company.EmptyRequiredField';
				this.alertParams = { fields: emptyFields.join(' ,') };
				this.$refs.cgLocAlert.showAlert();
				return;
			}

			Object.assign(body, this.selectedCompany);
			
			if(!this.$checkPermission('modify-company-smtp')) {
				delete body.smtp;
			} else {
				body.smtp = {};
				body.smtp.host = this.selectedCompany.smtp_host;
				if(this.selectedCompany.smtp_username && this.selectedCompany.smtp_username != '') {
					body.smtp.username = this.selectedCompany.smtp_username;
				}
				if(this.selectedCompany.smtp_password && this.selectedCompany.smtp_password != '') {
					body.smtp.password = this.selectedCompany.smtp_password;
					// Encrypt smtp password only if user has changed it
					var orgSmtp = JSON.parse(this.selectedCompany.smtp);
					body.encryptSmptPassword = (orgSmtp? body.smtp.password != orgSmtp.password : true);
				}
				body.smtp = JSON.stringify(body.smtp);
				
				delete body.smtp_host;
				delete body.smtp_password;
				delete body.smtp_username;
			}
			if(!this.$checkPermission('modify-company-labels')) {
				delete body.org_1;
				delete body.org_2;
				delete body.org_3;
				delete body.org_4;
			}
			if(!parseInt(body.use_org_1) && !parseInt(body.use_org_2) && !parseInt(body.use_org_3) && !parseInt(body.use_org_4)) {
				this.alertVariant = 'danger';
				this.alertMessage = 'Company.TooFewOrgLevelsMsg';
				this.$refs.cgLocAlert.showAlert();
				return
			}
			
			if(!this.$checkPermission('modify-company-email-domain')) {
				delete body.email_domain;
			} else {
				if(!this.isValidDomainSet(body.email_domain)) {
					this.invalidField = this.data.fields.filter((field) =>{ return field.id == 'email_domain' });
					this.alertCount = this.invalidDomain.length;
					this.alertMessage = 'Company.InvalidEmailDomainValue';
					this.alertParams = { "domain": this.invalidDomain.join(', ') };
					this.$refs.cgLocAlert.showAlert();
				} else {
					if(this.untouchEmailDomain && this.untouchEmailDomain.length) {
						body.email_domain = this.untouchEmailDomain.join(', ') + ', ' + body.email_domain;
					}
				}
			}
			if(!this.$checkPermission('modify-company-campaign')) {
				delete body.campaign_duration;
				delete body.send_email_duration;
				delete body.n_campaign_templates;
				delete body.n_min_campaign_templates;
				delete body.template_specific_assign_rate;
				delete body.n_onboarding_campaigns;
			} else {
				var campaignDurationIsoString = (body.campaign_duration) ? this.$moment.duration(body.campaign_duration).toISOString() : null;
				var sendEmailDurationIsoString = (body.send_email_duration) ? this.$moment.duration(body.send_email_duration).toISOString() : null;

				// check on campaignDuration
				if(body.campaign_duration) {
					// check that campaign_duration is expressed in iso8601 format
					if(campaignDurationIsoString == 'P0D') {
						this.invalidField = this.data.fields.filter((field) => { return field.id == 'campaign_duration' });
						this.alertMessage = 'Company.InvalidFieldValue';
						this.alertParams = { "field": this.$i18n.t(this.invalidField[0].label), "format": 'Duration ISO8601' };
					} else {
						// check that campaign duration is greater than sendEmailDuration value or the default sendEmailDuration
						if(sendEmailDurationIsoString && sendEmailDurationIsoString != 'P0D' && this.$moment.duration(campaignDurationIsoString).asMilliseconds() < this.$moment.duration(sendEmailDurationIsoString).asMilliseconds()) {
							this.invalidField = this.data.fields.filter((field) => { return field.id == 'campaign_duration' });
							this.alertMessage = 'Company.InvalidCampaignDuration';
						} else if(!sendEmailDurationIsoString && this.$moment.duration(campaignDurationIsoString).asMilliseconds() < this.$moment.duration(this.config.defaults.campaign.sendEmailPhaseDuration).asMilliseconds()) {
							this.invalidField = this.data.fields.filter((field) => { return field.id == 'campaign_duration' });
							this.alertMessage = 'Company.InvalidCampaignDuration';
						}
					}
				}

				// check on sendEmailDuration
				if(body.send_email_duration) {
					if(sendEmailDurationIsoString == 'P0D') {
						this.invalidField = this.data.fields.filter((field) => { return field.id == 'send_email_duration' });
						this.alertMessage = 'Company.InvalidFieldValue';
						this.alertParams = { "field": this.$i18n.t(this.invalidField[0].label), "format": 'Duration ISO8601' };
					} else {
						// check that sendEmailDuration is less than campaignDuration value or the default campaignDuration
						if(campaignDurationIsoString && campaignDurationIsoString != 'P0D' && this.$moment.duration(sendEmailDurationIsoString).asMilliseconds() > this.$moment.duration(campaignDurationIsoString).asMilliseconds()) {
							this.invalidField = this.data.fields.filter((field) => { return field.id == 'send_email_duration' });
							this.alertMessage = 'Company.InvalidSendEmailDuration';
						} else if(!campaignDurationIsoString && this.$moment.duration(sendEmailDurationIsoString).asMilliseconds() > this.$moment.duration(this.config.defaults.campaign.duration).asMilliseconds()) {
							this.invalidField = this.data.fields.filter((field) => { return field.id == 'send_email_duration' });
							this.alertMessage = 'Company.InvalidSendEmailDuration';
						}
					}
				}
				if(this.invalidField.length > 0) {
					this.alertVariant = 'danger';
					this.$refs.cgLocAlert.showAlert();
					return;
				}
				if(body.n_min_campaign_templates && parseInt(body.n_campaign_templates) < parseInt(body.n_min_campaign_templates) || (!body.n_campaign_templates && parseInt(this.config.defaults.campaign.proposedTemplates) < parseInt(body.n_min_campaign_templates))) {
					this.invalidField = this.data.fields.filter((field) =>{ return field.id == 'n_min_campaign_templates' });
					this.alertVariant = 'danger';
					this.alertMessage = 'Company.InvalidMinTemplate';
					this.$refs.cgLocAlert.showAlert();
					return;
				}
				if(body.n_campaign_templates  && parseInt(body.n_campaign_templates) < parseInt(body.n_min_campaign_templates ) || (!body.n_min_campaign_templates && parseInt(body.n_campaign_templates)< parseInt(this.config.defaults.campaign.minimumTemplates ))) {
					this.invalidField = this.data.fields.filter((field) =>{ return field.id == 'n_campaign_templates' });
					this.alertVariant = 'danger';
					this.alertMessage = 'Company.InvalidMinTemplate';
					this.$refs.cgLocAlert.showAlert();
					return;
				}

				// Convert the value of template_specific_assign_rate field from percentage to decimal
				body.template_specific_assign_rate = body.template_specific_assign_rate ? body.template_specific_assign_rate / 100 : null;

				if (body.template_specific_assign_rate && (parseFloat(body.template_specific_assign_rate) > 1 || parseFloat(body.template_specific_assign_rate) < 0)) {
					this.invalidField = this.data.fields.filter((field) =>{ return field.id == 'template_specific_assign_rate' });
					this.alertVariant = 'danger';
					this.alertMessage = 'Company.InvalidTemplateSpecificAssignRate';
					this.$refs.cgLocAlert.showAlert();
					return;
				}
			}
			
			this.inProgress = true;
			companyService.updateCompany(this.selectedCompany.company_id, body).then((res) => {
				// manage archive event only on archive status change
				if(this.defaultCompany.archived != body.archived) {
					this.$emit('companyToggleArchive', { company_id: this.selectedCompany.company_id, archived: body.archived });
				}
				this.$emit('companyUpdated', { company_id: this.selectedCompany.company_id, name: body.name });
				console.debug("Company - updateCompany success", res);
				this.alertVariant = 'success';
				this.alertMessage = 'Company.UpdatedCompany';
				// Update company name in companyList 
				this.companyList.forEach((comp) => { 
					if(comp.company_id == this.selectedCompany.company_id) { 
						comp.name = body.name;
						this.selectedCompanyName = body.name; 
						comp.archived = body.archived;
					} 
				});

				this.companyDataHasChanged = false;
				if(body.archived) {
					this.selectedCompany = null;
				}
			}).catch((error) => {
				console.error("Company - updateCompany error", error);
				this.alertVariant = 'danger';
                this.alertMessage = 'Company.CreateCompanyError';
				if(error.response.status == 400 && error.response.data.errors.length > 0 ) {
					let field = error.response.data.errors.filter((field) => field.id == 'country' );
					if(field) {
						this.alertMessage = 'Company.EmptyRequiredField'; 
						this.requiredEmpty = this.data.fields.filter((attr) => { return attr.id == 'country'; })
					} 
				}
			}).finally(() => {
				this.inProgress = false;
				this.$refs.cgLocAlert.showAlert();
			});
		},
		updateCompanyArchived() {
			if (this.selectedCompany.archived == 1) {
				this.selectedCompany.suspended = 1;
				this.suspended = 0;
			}
		},
		createAlert(variant, message) {
			this.alertVariant = variant;
			this.alertMessage = message;
			this.$refs.cgLocAlert.showAlert();
		},
		restoreDefaultCompany() {
			this.companyDataHasChanged = false;
			this.selectedCompany = Object.assign({}, this.defaultCompany);
		},
		async deleteCompany() {
			this.inProgress = true;
			let deleteCompanyPromise;

			console.debug("Company - deleteCompany - launching", this.keepData? "soft delete" : "hard delete");
			if (this.keepData) {
				deleteCompanyPromise = companyService.softDeleteCompany(this.selectedCompany.company_id);
			} else {
				deleteCompanyPromise = companyService.deleteCompany(this.selectedCompany.company_id);
			}
			deleteCompanyPromise.then((res) => {
				console.debug("Company - deleteCompany success", res);
				this.alertMessage = 'Company.DeletedCompany';
				this.alertVariant = 'success';

				// First remove the selectedCompany from the Company list in navbar dropdown
				let index = this.$account.companies.findIndex((c) => c.company_id == this.selectedCompany.company_id);
				this.$account.companies.splice(index, 1);

				// Keep on cleaning the session object
				Vue.prototype.$account.permissions = Vue.prototype.$account.permissions.filter((o) => o.company_id != this.selectedCompany.company_id);

				// If the logged user just erased the Company they're currently working on, select the first Company available and trigger app reload by Navbar
				if(localStorage.getItem('cg-company') === this.selectedCompany.company_id) {
					let company = this.$account.companies && this.$account.companies[0] ? this.$account.companies[0].company_id : null;
					this.$emit('companyDeleted', { company_id: company });
				} else {
					// Remove selectedCompany from the Company list in Company view and go back
					this.companyList.findIndex((c) => c.company_id == this.selectedCompany.company_id);
					this.companyList.splice(index, 1);
					this.selectedCompany = null;
				}
			}).catch((error) => {
				console.error("Company - deleteCompany error", error);
				this.alertMessage = 'Company.DeleteCompanyError';
				this.alertVariant = 'danger';
			}).finally(() => {
				this.inProgress = false;
				this.$refs.cgLocAlert.showAlert();
			});
		}
	},
	async created() {
		let configdata = await syspropService.getConfig();
		this.config = configdata.data;
		this.data.fields.forEach((field) => {
			if(field.section == 'campaign') {
				field.placeholder = '' + this.config.defaults.campaign[field.configKey];

				if (field.configKey === 'templateSpecificAssignRate') {
					field.placeholder = '' + this.config.defaults.campaign[field.configKey] * 100;
				}
			}
		})
		const currentCompanyId = localStorage.getItem("cg-company");

		if (this.canAccessToList) {
			this.getCompanyList();
		} else if(currentCompanyId) {
			this.getCompanyData(currentCompanyId);
		}
		
		if (this.$checkPermission('list-account') && currentCompanyId) {
			this.getAccountList(currentCompanyId);
		}

		this.accountValidDomains = this.$account.email_domain && this.$account.email_domain.split(/,| /).filter( dom => { return  dom != ''; } );

		// Get all languages that appear in template content
		var templateLanguages = await companyService.getTemplateLanguages(currentCompanyId);
		var landingPageLanguages = await companyService.getLandingPageLanguages(currentCompanyId);

		// Get all system languages
		var appLanguages = await syspropService.getLanguages();

		// Extract from the language arrays the ISO code
		var tl = templateLanguages.data.map(lan => lan.language_id);
		var lpl = landingPageLanguages.data.map(lan => lan.language_id);
		var al = appLanguages.data.map(lan => lan.language_id);

		// Merge the result to obtain the target available language dropdown list
		var usedLanguages = [...new Set(tl.concat(al).concat(lpl))];
		
		// Retrieve locale settings
		let locale = this.$i18n.locale;

		// Create the object in the form (key, value) with key the ISO code and value the translation accordin user's locale
		var obj = {};
		usedLanguages.forEach((lan) => {
			if(this.$langs[lan]) {
				obj[lan] = this.$langs[lan];
			}
		});

		Vue.set(this.data.fields.find(i => i.id === 'default_language'), 'options', obj);

		this.supportedCountry = this.$isoCountriesLanguages.getCountries(locale);
		Vue.set(this.data.fields.find(i => i.id === 'country'), 'options', this.supportedCountry);
	}
};
</script>
 
<style lang="less">
#company {
	.section-separator, .separator {
		border-bottom: @cg-border-light;
	}
	.separator:last-of-type {
		border-bottom: none;
	}
	.font-italic {
		font-style: italic;
	}
	img {
		&.small {
			height: 100px;
			width: 200px;
		}
		&.big {
			height: 200px;
			width: 300px;
		}
		object-fit: contain;
	}
	.company-logo {
		max-width: 300px;
		border: @cg-border-light; 
		margin: 30px auto 0;
	}
	.card-title {
		font-size: 1.3em;
	}
	.card-footer {
		background-color: #ffffff;
		border: none;
		font-size: 0.8em;
	}
	.line-height-30 {
		line-height: 30px;
	}
	.form-row:last-of-type {
		margin-bottom: 0;
	}
	.form-align-self-center {
        div {
            align-self: center !important;
        }
    }
	.btn-show-org-container {
		position: absolute;
		height: 100%;
		width: auto;
		right: 0;
		top: 4px;
	}
	.padding-right-50 {
		padding-right: 50px;
	}
	.archived-company {
		background-color: @cg-primary-light-bg-color;
		cursor: default;
	}
	.list-group-item {
		border: none;
		&:not(:first-child) {
			border-top: @cg-border-light;
		}
		&.disabled {
			color: #525252;
			& .btn-unarchive { 
				cursor: pointer; 
				pointer-events: all; 
			}
		}
	}
	.no-company-message {
		line-height: 200px;
		text-align: center;
	}
}
.first-line {
	text-align: center;
	margin-top: -22px;
	height: 60px;
}
.org-checkbox {
	padding-top: 6px; 
	text-align: center;
}
.checkbox-col-fixed {
	max-width: 100px;
	min-width: 80px;
}

.footer-message {
	font-size: 14px;
	font-style: italic;
}
.back-link {
	cursor: pointer;
}
.company-detail {
	margin-bottom: 5px;
	label {
		margin-top: 5px;
	}
}
.btn.disabled, .btn:disabled {
	cursor: auto;
}
svg.fa-info-circle {
    font-size: 16px;
}
.custom-file-label {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 80px;
}
.tooltip.b-tooltip {
	opacity: 1;
}
.tooltip-inner {
	min-width: 100px;
	background-color: white;
	color: #2c3e50;
	font-size: 12px;
	border: 1px solid #2c3e50;
	font-family: "Avenir", Helvetica, Arial, sans-serif;
}
.bs-tooltip-top .arrow::before {
	border-top-color: #2c3e50;
	border-width: 0.2rem 0.2rem 0;
}
#irreversibleDeleteModal {
	& .modal-dialog {
		padding: 40px;
		svg.exclamation-triangle {
			height:60px;
			width:60px; 
			color:#dc3545;
		}
	}
	& .cg-modal-footer {
		border-top: @cg-border-light;
		button:last-of-type {
			margin: 0 0 0 20px;
		}
		button:only-of-type {
			margin: 0;
		}
	}
}
</style>